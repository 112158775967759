import { useCallback, useEffect, useState } from 'react'
import { InstancesDialogType } from './types'
import {
  createInstance,
  updateInstanceById,
} from '../../../../services/instances/instances'
import { useGlobalContext } from '../../../../commonResources/GlobalContextProvider'

export const useInstancesDialogHook = (props: any): InstancesDialogType => {
  const { instanceContext, setInstanceContext } = useGlobalContext()

  const [changes, setChanges] = useState<boolean>(false)
  const handleChange = useCallback(
    (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setChanges(value.length > 0)

      if (props.isEditing) {
        updateInstanceContext(field, value)
      } else {
        createNewInstanceContext(field, value)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [instanceContext, props.isEditing]
  )

  const updateInstanceContext = (field: string, value: string) => {
    setInstanceContext({
      itemsSelected: instanceContext.itemsSelected.map((item) => ({
        ...item,
        [field]: value,
      })),
    })
  }

  const createNewInstanceContext = (
    field: string,
    value: string | undefined
  ) => {
    setInstanceContext({
      ...instanceContext,
      newInstance: {
        ...instanceContext.newInstance,
        [field]: value,
      },
    })
  }

  const resetState = () => {
    setChanges(false)
    props.clearCheckbox()
    props.toggleDialog()
    setInstanceContext({
      itemsSelected: [],
      newInstance: {
        defaultPort: '',
        hostname: '',
        ipAddress: '',
        prodPort: '',
      },
    })
  }

  const handleClear = useCallback(
    (field: string) => {
      const resetFunctions: { [key: string]: () => void } = {
        defaultPort: () => createNewInstanceContext('defaultPort', ''),
        hostname: () => createNewInstanceContext('hostname', ''),
        ipAddress: () => createNewInstanceContext('ipAddress', ''),
        prodPort: () => createNewInstanceContext('prodPort', ''),
      }

      setChanges(false)
      resetFunctions[field]?.()
    },
    [instanceContext]
  )

  const create = async () => {
    await createInstance(props.accessToken, instanceContext.newInstance).catch(
      (e) => {
        console.log('error: unable to create', e.message)
      }
    )
    props.toggleDialog()
    resetState()
    props.fetchData()
    setChanges(false)
  }

  const update = async () => {
    await updateInstanceById(
      props.accessToken,
      instanceContext.itemsSelected[0].id,
      instanceContext.itemsSelected[0]
    ).catch((e) => {
      console.log('error: unable to create', e.message)
    })
    props.toggleDialog()
    resetState()
    props.fetchData()
    setChanges(false)
  }

  useEffect(() => {
    if (props.isEditing) {
      setInstanceContext({
        ...instanceContext,
        newInstance: {
          ...instanceContext.newInstance,
          defaultPort: props.editData.defaultPort,
          hostname: props.editData.hostname,
          ipAddress: props.editData.ipAddress,
          prodPort: props.editData.prodPort,
        },
      })
    }
  }, [props.isEditing, props.editData])

  return {
    fetchData: props.fetchData,
    toggleDialog: props.toggleDialog,
    accessToken: props.accessToken,
    isEditing: props.isEditing,
    showDialog: props.showDialog,
    clearCheckbox: props.clearCheckbox,
    handleClear,
    create,
    handleChange,
    changes,
    resetState,
    update,
    setChanges,
  }
}
