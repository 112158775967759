import React, { createRef, useCallback, useEffect, useRef } from 'react'
import './styles.scss'
import { EyeExcelType } from './types'
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export'
import { useGlobalContext } from '../../commonResources/GlobalContextProvider'

const ExcelComponent = (props: EyeExcelType) => {
  const { excelData } = useGlobalContext()
  const _exporter: any = useRef(null)

  useEffect(() => {
    if (_exporter.current && excelData?.data.length > 0) {
      _exporter.current.save()
    }
  }, [excelData])
  return (
    <ExcelExport
      data-testid={'excel-table'}
      data={excelData?.data}
      collapsible={true}
      fileName="Eyes.xlsx"
      ref={_exporter}
    >
      {props?.fields?.map((field, idx) => (
        <ExcelExportColumn key={idx} field={field.name} title={field.title} />
      ))}
    </ExcelExport>
  )
}

export default ExcelComponent
