import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import {
  InputClearValue,
  InputSeparator,
  TextBox,
} from '@progress/kendo-react-inputs'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@progress/kendo-react-buttons'
import './styles.scss'
import { OrganizationsProps } from 'pages/Organizations/Types'
import DropDownListComponent from '../../../../components/dropdown'
import { useGlobalContext } from '../../../../commonResources/GlobalContextProvider'

const DialogComponent = (props: OrganizationsProps.DialogType) => {
  const {
    toggleDialog,
    isEditing,
    handleChange,
    update,
    create,
    handleClear,
    resetState,
    instanceList,
  } = props
  const { organizationContext, setOrganizationContext } = useGlobalContext()

  const { name, caratOrgName, instance } = isEditing
    ? organizationContext?.itemsSelected?.[0] || {}
    : organizationContext?.newInstance || {}

  return (
    <Dialog
      data-testid={'dialog'}
      className="organizations-dialog-test"
      height={500}
      width={450}
      onClose={() => toggleDialog()}
    >
      <h2 className="dialog-header-text">
        {props.isEditing
          ? 'EDIT SAPPHIRE ORGANIZATION'
          : 'CREATE SAPPHIRE ORGANIZATION'}
      </h2>
      <form onSubmit={(e) => e.preventDefault()} data-testid={'form'}>
        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'org-name'}>Organziation Name</div>
          <TextBox
            data-testid={'org-txt-name'}
            size={'large'}
            fillMode={'flat'}
            value={name}
            onChange={(e) => {
              props.handleChange('name', e)
            }}
            placeholder="Ex: IBM-**"
            suffix={() => (
              <React.Fragment>
                {name !== '' && (
                  <InputClearValue onClick={() => handleClear('name')}>
                    <FontAwesomeIcon icon={faXmark} />
                  </InputClearValue>
                )}
                <InputSeparator />
              </React.Fragment>
            )}
          />
        </fieldset>

        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'caratOrgName'}>Carat Org Name</div>
          <TextBox
            size={'large'}
            fillMode={'flat'}
            value={caratOrgName}
            onChange={(e) => {
              handleChange('caratOrgName', e)
            }}
            placeholder="Ex: 253"
            suffix={() => (
              <React.Fragment>
                {caratOrgName !== '' && (
                  <InputClearValue onClick={() => handleClear('caratOrgName')}>
                    <FontAwesomeIcon icon={faXmark} />
                  </InputClearValue>
                )}
                <InputSeparator />
              </React.Fragment>
            )}
          />
        </fieldset>

        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'instance'}>Instance</div>
          <DropDownListComponent
            data={instanceList}
            value={instance}
            textField={'hostname'}
            onChange={(e) => {
              handleChange('instance', e)
            }}
          />
        </fieldset>
      </form>
      <DialogActionsBar>
        <Button
          className={'dialog-btn'}
          size={'large'}
          fillMode={'solid'}
          onClick={() => {
            setOrganizationContext({
              ...organizationContext,
              itemsSelected: [],
            })
            if (isEditing) {
              toggleDialog()
            } else {
              resetState()
            }
          }}
        >
          Cancel
        </Button>
        <Button
          className={'dialog-btn'}
          size={'large'}
          fillMode={'solid'}
          onClick={() => (isEditing ? update() : create())}
        >
          {isEditing ? 'Update' : 'Submit'}
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default DialogComponent
