import { ExcelData } from 'pages/Eyes/Types'
import React, { createContext, useContext, useState } from 'react'

const GlobalContext = createContext({})
export const useGlobalContext: any = () => useContext(GlobalContext)

const GlobalContextProvider = ({ children }) => {
  const [eyesContext, setEyesContext] = useState<any>({
    itemsSelected: [],
    newEye: {
      macAddress: '',
      serialNumber: '',
      organization: undefined,
    },
  })
  const [instanceContext, setInstanceContext] = useState<any>({
    itemsSelected: [],
    newInstance: {
      defaultPort: '',
      hostname: '',
      ipAddress: '',
      prodPort: '',
    },
  })
  const [organizationContext, setOrganizationContext] = useState<any>({
    itemsSelected: [],
    newOrganization: {
      name: '',
      caratOrgName: '',
      instance: undefined,
    },
  })

  const [excelData, setExcelData] = useState<ExcelData>({
    total: 0,
    data: [],
  })

  return (
    <GlobalContext.Provider
      value={{
        eyesContext,
        setEyesContext,
        instanceContext,
        setInstanceContext,
        organizationContext,
        setOrganizationContext,
        excelData,
        setExcelData,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
