import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import {
  InputClearValue,
  InputSeparator,
  TextBox,
} from '@progress/kendo-react-inputs'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@progress/kendo-react-buttons'
import './styles.scss'
import { InstancesDialogType } from './types'
import { useGlobalContext } from '../../../../commonResources/GlobalContextProvider'

export const ClearInputComponent = (props, type) => (
  <React.Fragment>
    {props[type] !== '' && (
      <InputClearValue
        data-testid={'clear-input'}
        onClick={() => props.handleClear(type)}
      >
        <FontAwesomeIcon icon={faXmark} />
      </InputClearValue>
    )}
    <InputSeparator />
  </React.Fragment>
)

const DialogInstancesComponent = (props: InstancesDialogType) => {
  const { toggleDialog, isEditing, handleChange, resetState, update, create } =
    props
  const { instanceContext, setInstanceContext } = useGlobalContext()
  const { defaultPort, hostname, ipAddress, prodPort } = isEditing
    ? instanceContext?.itemsSelected?.[0] || {}
    : instanceContext?.newInstance || {}

  return (
    <Dialog
      data-testid={'dialog'}
      height={500}
      width={450}
      onClose={() => toggleDialog()}
    >
      <h2 className="dialog-header-text">
        {isEditing ? 'EDIT SAPPHIRE INSTANCE' : 'CREATE SAPPHIRE INSTANCE'}
      </h2>
      <form onSubmit={(e) => e.preventDefault()} data-testid={'form'}>
        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'defaultPort'}>Default Port</div>
          <TextBox
            size={'large'}
            fillMode={'flat'}
            value={defaultPort}
            onChange={(e) => {
              handleChange(
                'defaultPort',
                e as unknown as React.ChangeEvent<HTMLInputElement>
              )
            }}
            placeholder="Ex: 6###5"
            suffix={() => ClearInputComponent(props, 'defaultPort')}
          />
        </fieldset>

        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'hostname'}>Hostname</div>
          <TextBox
            data-testid={'hostname-input'}
            size={'large'}
            fillMode={'flat'}
            value={hostname}
            onChange={(e) => {
              handleChange(
                'hostname',
                e as unknown as React.ChangeEvent<HTMLInputElement>
              )
            }}
            placeholder="Ex: example.example.com"
            suffix={() => ClearInputComponent(props, 'hostname')}
          />
        </fieldset>

        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'ipAddress'}>Ip Address</div>
          <TextBox
            data-testid={'ipAddress-input'}
            size={'large'}
            fillMode={'flat'}
            value={ipAddress}
            onChange={(e) => {
              handleChange(
                'ipAddress',
                e as unknown as React.ChangeEvent<HTMLInputElement>
              )
            }}
            placeholder="Ex: 192.###.1.1"
            suffix={() => ClearInputComponent(props, 'ipAddress')}
          />
        </fieldset>

        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'prodPort'}>Prod Port</div>
          <TextBox
            data-testid={'prodPort-input'}
            size={'large'}
            fillMode={'flat'}
            value={prodPort}
            onChange={(e) => {
              handleChange(
                'prodPort',
                e as unknown as React.ChangeEvent<HTMLInputElement>
              )
            }}
            placeholder="Ex: 1##4"
            suffix={() => ClearInputComponent(props, 'prodPort')}
          />
        </fieldset>
      </form>
      <DialogActionsBar>
        <Button
          className={'dialog-btn'}
          size={'large'}
          fillMode={'solid'}
          onClick={() => {
            setInstanceContext({
              ...instanceContext,
              itemsSelected: [],
            })
            if (isEditing) {
              toggleDialog()
            } else {
              resetState()
            }
          }}
        >
          Cancel
        </Button>
        <Button
          className={'dialog-btn'}
          size={'large'}
          fillMode={'solid'}
          onClick={() => (isEditing ? update() : create())}
        >
          {isEditing ? 'Update' : 'Submit'}
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default DialogInstancesComponent
