import { useCallback, useContext, useEffect, useState } from 'react'
import { getter, SortDescriptor } from '@progress/kendo-data-query'
import {
  GridHeaderSelectionChangeEvent,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'

import { AccessTokenContext } from '../../commonResources/AccessTokenContext'
import { Instance, InstancesProps } from './Types'
import {
  deleteInstanceById,
  getAllInstances,
} from '../../services/instances/instances'
import { asyncCurryHelper } from '../../services/app/app'
import { useGlobalContext } from '../../commonResources/GlobalContextProvider'

export const setSearchOptions = (field: String, newValue: any) => {
  const idx = SapphireInstanceeHelper.filters.findIndex(
    (item) => item.field === field
  )
  if (idx !== -1) SapphireInstanceeHelper.filters.splice(idx, 1, newValue)
  if (idx === -1) SapphireInstanceeHelper.filters.push(newValue)
}

export const SapphireInstanceeHelper: any = {
  itemsToDelete: [],
  pagination: {
    page: 0,
    pages: 1,
    perPage: 20,
    total: 20,
  },
  hasAccessToken: false,
  filters: [],
}

export const useInstancesHook = (): InstancesProps => {
  const accessToken = useContext<string | null>(AccessTokenContext)
  SapphireInstanceeHelper.hasAccessToken = accessToken !== null
  const { setInstanceContext, instanceContext } = useGlobalContext()
  const [data, setData] = useState<Instance[]>([
    {
      id: '',
      defaultPort: 0,
      hostname: '',
      ipAddress: '',
      prodPort: 0,
    },
  ])
  const [sort, setSort] = useState<Array<SortDescriptor>>([
    { field: 'hostname', dir: 'asc' },
  ])
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[]
  }>({})
  const [isSelected, setIsSelected] = useState<any>(null)
  const [page, setPage] = useState<any>({ take: 20, page: 0 })
  const [pageSizeValue, setPageSizeValue] = useState<number>(0)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [editData, setEditData] = useState<Instance>({
    id: '',
    defaultPort: 0,
    hostname: '',
    ipAddress: '',
    prodPort: 0,
  })

  const DATA_ITEM_KEY: string = 'id'
  const SELECTED_FIELD: string = 'selected'
  const idGetter = getter(DATA_ITEM_KEY)
  const [isCheckboxSelected, setIsCheckboxSelected] = useState<any>({})
  const [confirmDeleteMessage, setConfirmDeleteMessage] = useState('')
  const parseSort = (sort) => {
    return sort.map((item) => {
      return `${item.field},${item.dir}`
    })
  }
  const sortChange = async (event: GridSortChangeEvent) => {
    let response
    setData(response?.data.content)
    setSort(event.sort)

    try {
      if (
        SapphireInstanceeHelper.filters.some((item) => item.field === 'page')
      ) {
        response = await asyncCurryHelper(getAllInstances)(
          accessToken,
          SapphireInstanceeHelper.filters.find((item) => item.field === 'page')
            .value,
          20,
          parseSort(event.sort)
        )
      } else {
        response = await asyncCurryHelper(getAllInstances)(
          accessToken,
          page.page,
          page.take,
          parseSort(event.sort)
        )
      }

      setData(response?.data.content)
      setSort(event.sort)
    } catch (error) {
      console.log('Unable to sort Sapphire Organizations:', error)
    }
  }
  const fetchData = async () => {
    if (SapphireInstanceeHelper.hasAccessToken) {
      const sortData = parseSort(sort)
      const response = await getAllInstances(
        accessToken,
        page.page,
        page.take,
        sortData
      )
      setData(response?.data.content)
      setPageSizeValue(response?.data.pagination.total)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  const addSapphireInstance = () => {
    setIsEditing(false)
    setSelectedState({})
    setShowDialog(true)
  }

  const editSapphireInstance = () => {
    setShowDialog(true)
    setIsEditing(true)
  }
  const deleteSapphireInstance = async () => {
    const id = instanceContext.itemsSelected[0].id
    await deleteInstanceById(accessToken, id)
    fetchData()
    setSelectedState({})
  }

  const handleConfirmDelete = (objectData: any) => {
    let newDeleteMessage = 'Continue to delete ' + objectData.hostname + '?'
    setConfirmDeleteMessage(newDeleteMessage)
  }

  const pageChange = async (e) => {
    const page = Math.floor(e.page.skip / e.page.take)
    const pageSort = sort[0].field ? `${sort[0].field},${sort[0].dir}` : 'asc'
    const response = await getAllInstances(accessToken, page, 20, pageSort)

    setData(response?.data.content)
    setPageSizeValue(response?.data.pagination.total)
    setPage({
      ...e.page,
      take: 20,
    })
    setSearchOptions('page', {
      field: 'page',
      value: page,
    })
  }

  const toggleDialog = useCallback(() => {
    setShowDialog(!showDialog)
  }, [showDialog])

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent | any) => {
      const { isChecked, dataItem } = event

      isChecked ? setIsSelected(dataItem) : setIsSelected(null)

      setInstanceContext((prevContext) => ({
        ...prevContext,
        itemsSelected: isChecked
          ? [...prevContext.itemsSelected, dataItem]
          : prevContext.itemsSelected.filter((item) => item.id !== dataItem.id),
      }))
    },
    []
  )

  const hasTruthyValue = (obj) => {
    const array: any = Object.values(obj).filter(Boolean)
    return array.length === 1
  }

  const shouldDisableButton = () => {
    return instanceContext.itemsSelected.length === 0
  }

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent | any) => {
      const newSelectedState = {}

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = event.syntheticEvent.target.checked
      })

      SapphireInstanceeHelper.itemsToDelete = event.dataItems
      setSelectedState(newSelectedState)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {}, [toggleDialog])
  return {
    onSelectChange: undefined,
    data,
    sort,
    sortChange,
    selectedState,
    SELECTED_FIELD,
    idGetter,
    addSapphireInstance,
    deleteSapphireInstance,
    handleConfirmDelete,
    confirmDeleteMessage,
    setConfirmDeleteMessage,
    onSelectionChange,
    onHeaderSelectionChange,
    showDialog,
    toggleDialog,
    _setData: setData,
    _setPage: setPage,
    _setPageSizeValue: setPageSizeValue,
    _hasTruthyValue: hasTruthyValue,
    shouldDisableButton,
    accessToken,
    fetchData,
    pageSizeValue,
    pageChange,
    page,
    parseSort,
    editSapphireInstance,
    isEditing,
    setIsEditing,
    setEditData,
    editData,
    isSelected,
    setIsSelected,
    isCheckboxSelected,
    setIsCheckboxSelected,
  }
}
