import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import {
  InputClearValue,
  InputSeparator,
  TextBox,
} from '@progress/kendo-react-inputs'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { EyeDialogType } from './types'
import { Button } from '@progress/kendo-react-buttons'
import './styles.scss'
import DropDownListComponent from '../../../../components/dropdown'
import { useGlobalContext } from '../../../../commonResources/GlobalContextProvider'

const DialogEyeComponent = (props: EyeDialogType) => {
  const {
    toggleDialog,
    isEditing,
    handleChange,
    handleClear,
    resetState,
    update,
    create,
    clearCheckbox,
  } = props
  const { eyesContext, setEyesContext } = useGlobalContext()
  const { organization, macAddress, serialNumber } = isEditing
    ? eyesContext?.itemsSelected?.[0] || {}
    : eyesContext?.newEye || {}

  return (
    <Dialog
      data-testid={'dialog'}
      height={500}
      width={450}
      onClose={() => toggleDialog()}
    >
      <h2 className="dialog-header-text">
        {isEditing ? 'EDIT SAPPHIRE EYE' : 'CREATE SAPPHIRE EYE'}
      </h2>
      <form onSubmit={(e) => e.preventDefault()} data-testid={'form'}>
        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'macAddress'}>Mac Address</div>
          <TextBox
            size={'large'}
            fillMode={'flat'}
            value={macAddress}
            onChange={(e) => {
              handleChange('macAddress', e)
            }}
            placeholder="Ex: 00-1A-C5-1A-7C-00"
            suffix={() => (
              <React.Fragment>
                {macAddress !== '' && (
                  <InputClearValue onClick={() => handleClear('macAddress')}>
                    <FontAwesomeIcon icon={faXmark} />
                  </InputClearValue>
                )}
                <InputSeparator />
              </React.Fragment>
            )}
          />
        </fieldset>

        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'serialNumber'}>Serial Number</div>
          <TextBox
            size={'large'}
            fillMode={'flat'}
            value={serialNumber}
            onChange={(e) => {
              handleChange('serialNumber', e)
            }}
            placeholder="Ex: JK123YINAO"
            suffix={() => (
              <React.Fragment>
                {serialNumber !== '' && (
                  <InputClearValue onClick={() => handleClear('serialNumber')}>
                    <FontAwesomeIcon icon={faXmark} />
                  </InputClearValue>
                )}
                <InputSeparator />
              </React.Fragment>
            )}
          />
        </fieldset>
        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'organization'}>Organization</div>
          <DropDownListComponent
            data={props.organizationList}
            textField={'name'}
            value={organization}
            onChange={(e) => handleChange('organization', e)}
          />
        </fieldset>
      </form>
      <DialogActionsBar>
        <Button
          data-testid={'cancel-btn'}
          className={'dialog-btn'}
          size={'large'}
          fillMode={'solid'}
          onClick={() => {
            setEyesContext({
              ...eyesContext,
              itemsSelected: [],
            })
            if (isEditing) {
              if (clearCheckbox) clearCheckbox()
              toggleDialog()
            } else {
              resetState()
            }
          }}
        >
          Cancel
        </Button>
        <Button
          className={'dialog-btn'}
          size={'large'}
          fillMode={'solid'}
          onClick={() => (isEditing ? update() : create())}
        >
          {isEditing ? 'Update' : 'Submit'}
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default DialogEyeComponent
