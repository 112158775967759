import { useCallback, useEffect, useState } from 'react'
import {
  createSapphireEye,
  updateSapphireEyeById,
} from '../../../../services/eyes/eyes'
import { getAllOrganizations } from '../../../../services/organizations/organizations'
import { useGlobalContext } from '../../../../commonResources/GlobalContextProvider'
import { EyeDialogType } from './types'

export const useEyeDialogHook = (props: any): EyeDialogType => {
  const [changes, setChanges] = useState<boolean>(false)
  const [organizationList, setOrganizationList] = useState<any>([])
  const { eyesContext, setEyesContext } = useGlobalContext()

  const handleChange = useCallback(
    (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setChanges(value.length > 0)

      if (props.isEditing) {
        updateEyeContext(field, value)
      } else {
        createNewEyeContext(field, value)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eyesContext, props.isEditing]
  )

  const updateEyeContext = (field: string, value: string) => {
    setEyesContext({
      itemsSelected: eyesContext.itemsSelected.map((item) => ({
        ...item,
        [field]: value,
      })),
    })
  }

  const createNewEyeContext = (field: string, value: string | undefined) => {
    setEyesContext({
      ...eyesContext,
      newEye: {
        ...eyesContext.newEye,
        [field]: value,
      },
    })
  }

  const resetState = () => {
    setEyesContext({
      itemsSelected: [],
      newEye: { macAddress: '', serialNumber: '', organization: undefined },
    })
    setChanges(false)
    props.clearCheckbox()
    props.toggleDialog()
  }

  const handleClear = useCallback(
    (field: string) => {
      const resetFunctions: { [key: string]: () => void } = {
        macAddress: () => createNewEyeContext('macAddress', ''),
        serialNumber: () => createNewEyeContext('serialNumber', ''),
        organization: () => createNewEyeContext('organization', undefined),
      }

      setChanges(false)
      resetFunctions[field]?.()
    },
    [eyesContext]
  )

  const create = async () => {
    const { macAddress, serialNumber, organization } = eyesContext.newEye
    const data = {
      macAddress,
      serialNumber,
      organizationId: organization?.id,
    }

    try {
      await createSapphireEye(props.accessToken, data)
      resetState()
      props.fetchData()
    } catch (e: any) {
      console.error('Error creating SapphireEye:', e.message)
    }
  }

  const update = async () => {
    const { organization, macAddress, serialNumber } =
      eyesContext.itemsSelected?.[0] || {}
    const organizationId = organizationList.find(
      (org) => org.name === organization.name
    )?.id

    const data = {
      macAddress,
      serialNumber,
      ...(organizationId && { organizationId }),
    }

    try {
      await updateSapphireEyeById(
        props.accessToken,
        eyesContext.itemsSelected[0].id,
        data
      )

      resetState()
      props.fetchData()
    } catch (e: any) {
      console.error('Error updating SapphireEye:', e.message)
    }
  }

  const organizationData = async () => {
    try {
      const response = await getAllOrganizations(props.accessToken, 0, 1000, [
        'name,asc',
      ])
      const organizations =
        response?.data?.content?.map(({ id, name }) => ({ id, name })) || []
      organizations.push({ id: null, name: 'No Organization Specified' })
      setOrganizationList(organizations)
    } catch (e: any) {
      console.error('Error fetching organizations:', e.message)
    }
  }

  useEffect(() => {
    if (props.showDialog) {
      organizationData()
    }
  }, [props.showDialog])

  return {
    ...props,
    changes,
    handleChange,
    handleClear,
    create,
    update,
    resetState,
    organizationList,
    updateEyeContext,
    createNewEyeContext,
  }
}
